import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import MarkdownWrapper from '../../../../components/MarkdownWrapper';
import Layout from '../../../../components/Layout';
export const _frontmatter = {
  "title": "AWS IAM Principal",
  "path": "/knowledge/tech/aws",
  "date": "2023-02-04T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout title={props.pageContext.frontmatter.title} location={props.path} mdxType="Layout">
      <MarkdownWrapper mdxType="MarkdownWrapper">
        <h2 {...{
          "id": "principals",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#principals",
            "aria-label": "principals permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Principals`}</h2>
        <p>{`A principal is an AWS entity that can assume a role and take its permissions to perform some actions in AWS.`}</p>
        <p>{`It could be:`}</p>
        <ul>
          <li parentName="ul">{`AWS account`}</li>
          <li parentName="ul">{`IAM role`}</li>
          <li parentName="ul">{`IAM user`}</li>
          <li parentName="ul">{`Federated SAML users`}</li>
        </ul>
        <h3 {...{
          "id": "service-principal",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#service-principal",
            "aria-label": "service principal permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Service Principal`}</h3>
        <p>{`The method `}<a parentName="p" {...{
            "href": "https://docs.aws.amazon.com/cdk/api/v2/docs/aws-cdk-lib.aws_iam.ServicePrincipal.html"
          }}><inlineCode parentName="a">{`ServicePrincipal`}</inlineCode></a>{` from the CDK is used to represent the service that can assume the role.`}</p>
        <p><a parentName="p" {...{
            "href": "./api-gateway#logging-1"
          }}>{`Remember when we wanted to log the requests made by API Gateway to CloudWatch?`}</a>{` We had to first create a service role that has the permissions to log to CloudWatch and this role can ONLY be assumed by API Gateway service.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "726px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/627044880d9bfc2112de8adc025907e6/f8067/iam-service-principal.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "55.333333333333336%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA9ElEQVQoz52S6aqEMAyFff+X84ePICrigvuuGb5AinOpd4YpHNI2J6dZGuz7LsdxKHz7bdtkmiaHuw9732MDE/AJnucpwzBImqaSZZnizgdwvILm8AWwrutS+zfDtm21CosPLO1lWRzWdVUCwMfZOPaInfM8l3EclfOWYVEUUpalEuq6lnmeldj3vVruEKCP+KqqUj6WWHhOkNe6rpOmaRT0jTtKIdiEsNzhg0MMQljOrmQrgyDrh+FpaP9OGRF6QPq8ZL18GtTTpF2GZMbXCMNQoijSyVGqL6tPdypIMCJxHEuSJK4f32Z4R2CB9s9YvwiZ4AuIZVy2FwOwHgAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "iam service principal diagram",
                "title": "iam service principal diagram",
                "src": "/static/627044880d9bfc2112de8adc025907e6/f8067/iam-service-principal.png",
                "srcSet": ["/static/627044880d9bfc2112de8adc025907e6/5a46d/iam-service-principal.png 300w", "/static/627044880d9bfc2112de8adc025907e6/0a47e/iam-service-principal.png 600w", "/static/627044880d9bfc2112de8adc025907e6/f8067/iam-service-principal.png 726w"],
                "sizes": "(max-width: 726px) 100vw, 726px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`The service principal for allowing API Gateway to create log events in CloudWatch has the following policy:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
  "Version": "2012-10-17",
  "Statement": [
    {
      "Effect": "Allow",
      "Action": [
        "logs:CreateLogGroup",
        "logs:CreateLogStream",
        "logs:DescribeLogGroups",
        "logs:DescribeLogStreams",
        "logs:PutLogEvents",
        "logs:GetLogEvents",
        "logs:FilterLogEvents"
      ],
      "Resource": "*"
    }
  ]
}
`}</code></pre>
        <p>{`To do the same thing using CDK:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-ts"
          }}>{`import * as iam from 'aws-cdk-lib/aws-iam'
import * as cdk from 'aws-cdk-lib'

export class CdkStack extends cdk.Stack {
  constructor(scope: cdk.App, id: string, props?: cdk.StackProps) {
    super(scope, id, props)

    // Create the role for API Gateway
    const role = new iam.Role(this, 'ApiGatewayRoleFromCDK', {
      // Which entity can use its permissions
      assumedBy: new iam.ServicePrincipal('apigateway.amazonaws.com'),
    })

    // Create the policy for logging in CloudWatch
    const policy = new iam.Policy(
      this,
      'ApiGatewayCloudWatchLogPolicyFromCDK',
      {
        policyName: 'ApiGatewayCloudWatchLogPolicyFromCDK',
        statements: [
          new iam.PolicyStatement({
            actions: [
              'logs:CreateLogGroup',
              'logs:CreateLogStream',
              'logs:DescribeLogGroups',
              'logs:DescribeLogStreams',
              'logs:PutLogEvents',
              'logs:GetLogEvents',
              'logs:FilterLogEvents',
            ],
            resources: ['*'],
          }),
        ],
      }
    )

    // Attach the policy to the role
    role.attachInlinePolicy(policy)
  }
}
`}</code></pre>
        <p>{`To confirm the role has the correct principal and policy, we can go to IAM -> Roles -> The new role -> Trust relationships:`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/0efb11ae3810c8948954a13b69f799dc/ce6cc/iam-trust-relationships.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "56.333333333333336%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA3ElEQVQoz61Si27DIBDj/7+UhK4Q3keaePJtqaqq2pppJ1lHyNmAZeO9R0pJEWO891IKtm3Dvu+/gjXGUK4JIegHyc/1jtgB8qljqCoiGGN9eTKLw7VWnTv2H/8/rg2FbkOw1QW30fUUEnv/Wr8i/VSGHlprkWJEzllvQrHWmvZ3QL+nadJunHOw8wfsxcEvXjcpehbkUVxvWEqFdQHz9QIfPVLJ+uyzWNcVZppnxJQRc0VgZHJBqQ25nMD3fOvC2Cxo7fBD0NS/v0EYG4ZYpD/h/HOPVNxz+B+gh59cZ1uldYGrSQAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "iam trust relationships",
                "title": "iam trust relationships",
                "src": "/static/0efb11ae3810c8948954a13b69f799dc/c1b63/iam-trust-relationships.png",
                "srcSet": ["/static/0efb11ae3810c8948954a13b69f799dc/5a46d/iam-trust-relationships.png 300w", "/static/0efb11ae3810c8948954a13b69f799dc/0a47e/iam-trust-relationships.png 600w", "/static/0efb11ae3810c8948954a13b69f799dc/c1b63/iam-trust-relationships.png 1200w", "/static/0efb11ae3810c8948954a13b69f799dc/ce6cc/iam-trust-relationships.png 1551w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`As we can see, it has API Gateway as the service principal.`}</p>
        <h3 {...{
          "id": "more-info",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#more-info",
            "aria-label": "more info permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`More info`}</h3>
        <p>{`When manually creating a role from IAM console, this is how each entity is technically named:`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/14bd06d007dfb47323664524568998de/89048/iam-entity-names.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "58.333333333333336%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAABgklEQVQoz42Sy4rbMBiF/f5vUehmFi2U2XTRZ2jXLaSQhOBYjixbF190+4qcNJmUmWZ+OEhI8uFcXHnv6TpJ3/frqo0j83fOu5wz+XZ4u33lsCqEdX2kEQLrHH6xhLklBb2SpJQuTxPRK6IfyDmRLmQ5OlIYVuQcqOaQ+d0M7EXHaQSpJbvNJ1rxnXkOq+pxWrCm4bR9Qh2emacBKTusnWh3Xzn8+sBx85EwC6qUQZkR0Vlkd6DefqHePqNOP1iWQN8rXFHuPSEWxWfVMUZCCMRY9pmYzrFUJQdrNEYbrJZY9RPbb1jG00qgtWaappXQLwspxWu2r021LAtt254/CmnF5ARO75ldfVfA20gXZKoivzTsnMX7yDxKuv1n5O4J3Xy7PnwvroTjOF6sBUJ6+WvwkOSlg6qEK5oGKSVKKYwxOGcYnSGlsBbwHhSeslYpZdr2xPF4RAhxJS4lPM7uXuVaSogJqQaU6hiGgXmeb616f2fpf3O1/NZFQbHwb0aPSvkDB/Wo3/FCnfcAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "iam entity names",
                "title": "iam entity names",
                "src": "/static/14bd06d007dfb47323664524568998de/c1b63/iam-entity-names.png",
                "srcSet": ["/static/14bd06d007dfb47323664524568998de/5a46d/iam-entity-names.png 300w", "/static/14bd06d007dfb47323664524568998de/0a47e/iam-entity-names.png 600w", "/static/14bd06d007dfb47323664524568998de/c1b63/iam-entity-names.png 1200w", "/static/14bd06d007dfb47323664524568998de/89048/iam-entity-names.png 1242w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "resources",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#resources",
            "aria-label": "resources permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Resources`}</h3>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://bobbyhadz.com/blog/aws-cdk-iam-principal#service-principal-example-in-aws-cdk"
            }}>{`IAM Principal Examples in CDK`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.aws.amazon.com/IAM/latest/UserGuide/intro-structure.html"
            }}>{`Understanding IAM`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.amazonaws.cn/en_us/IAM/latest/UserGuide/using-service-linked-roles.html"
            }}>{`service-linked role vs service role`}</a></li>
        </ul>
      </MarkdownWrapper>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      